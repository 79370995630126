import React from "react";
import clsx from "clsx";

import ChevronSVG from "modules/theme/icons/chevrons/chevron-text-with-subheading.svg";
import RichText from "components/RichText";
import { stripTags } from "modules/utils";

import * as styles from "./styles.module.scss";

export type TextWithSubheadingProps = {
  content: string;
  noContainer?: boolean;
  title?: string;
  enableTopBorder?: boolean;
  enableBottomBorder?: boolean;
  lazyload?: boolean;
};

const TextWithSubheading: React.FC<TextWithSubheadingProps> = ({
  content,
  noContainer,
  title,
  enableTopBorder,
  enableBottomBorder,
  lazyload,
}) => {
  const className = clsx(
    styles.textWithSubheading,
    noContainer && styles.noContainer,
    enableBottomBorder && styles.enableBottomBorder,
    enableTopBorder && styles.enableTopBorder,
    lazyload && "componentLazyload"
  );
  return noContainer ? (
    <div className={className}>
      <RichText content={content} />
    </div>
  ) : (
    <div className={className}>
      <div className="container small">
        <div>
          <div className={styles.titleContainer}>
            {title && (
              <React.Fragment>
                <h2>{stripTags(title)}</h2>
                <ChevronSVG />
              </React.Fragment>
            )}
          </div>
          <RichText content={content} />
        </div>
      </div>
    </div>
  );
};

export default TextWithSubheading;
