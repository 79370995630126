import { graphql, useStaticQuery } from "gatsby";

export type useBreadcrumbsProps = {
  pathname: string;
  crumbLabel: string;
}[];

const getAllPageTitles = (pathname: string) => {
  const data = useStaticQuery(graphql`
    {
      genericPages: allContentstackAxGenericPage {
        edges {
          node {
            title
            url
          }
        }
      }
      newsPages: allContentstackNewsPage {
        edges {
          node {
            title
            url
          }
        }
      }
      resourceHubPages: allContentstackResourceHubPage {
        edges {
          node {
            title
            slug
            categorisation {
              content_type {
                slug
              }
            }
          }
        }
      }
      organizations: allContentstackOrganizations {
        edges {
          node {
            name
            slug
          }
        }
      }
      newsList: contentstackNewsListPage {
        title
        url
      }
      resourceHubList: contentstackResourceHubListPage {
        title
        url
      }
      scrList: contentstackScrListPage {
        title
        url
      }
      publicationsList: contentstackPublicationsListPage {
        title
        url
      }
      faqsPage: contentstackFaqsPage {
        title
        url
      }
      contactPage: contentstackContactPage {
        title
        url
      }
      atoList: contentstackAtoListPage {
        title
        url
      }
      acpList: contentstackAcpListPage {
        title
        url
      }
    }
  `);

  (String.prototype as any).equalsCI = function (s: any) {
    const regexp = RegExp("^" + this.replace(/[.\\+*?\[\^\]$(){}=!<>|:-]/g, "\\$&") + "$", "i");
    return regexp.test(s);
  };

  const acpList = (pathname as any).equalsCI(data.acpList?.url);
  const atoList = (pathname as any).equalsCI(data.atoList?.url);
  const contactPage = (pathname as any).equalsCI(data.contactPage?.url);
  const faqsPage = (pathname as any).equalsCI(data.faqsPage?.url);
  const newsListPage = (pathname as any).equalsCI(data.newsList?.url);
  const publicationsList = (pathname as any).equalsCI(data.publicationsList?.url);
  const resourceHubList = (pathname as any).equalsCI("/resource-hub");
  const scrList = (pathname as any).equalsCI(data.scrList?.url);
  const genericPage = data.genericPages.edges.filter((i: any) => (pathname as any).equalsCI(i.node?.url)).length > 0;
  const newsPage = data.newsPages.edges.filter((i: any) => (pathname as any).equalsCI(i.node?.url)).length > 0;
  const organizations =
    data.organizations.edges.filter((i: any) => {
      const organizationsPath = `${data.acpList.url}/`;
      return ((organizationsPath + pathname) as any).equalsCI(organizationsPath + i.node?.slug);
    }).length > 0;
  const resourceHub =
    data.resourceHubPages.edges.filter((i: any) => {
      const resourceHubPath = `/resource-hub/${i.node.categorisation.content_type[0]?.slug}/`;
      return ((resourceHubPath + pathname) as any).equalsCI(resourceHubPath + i.node?.slug);
    }).length > 0;

  switch (true) {
    case acpList:
      return data.acpList.title;
    case atoList:
      return data.atoList.title;
    case contactPage:
      return data.contactPage.title;
    case faqsPage:
      return data.faqsPage.title;
    case newsListPage:
      return data.newsList.title;
    case publicationsList:
      return data.publicationsList.title;
    case resourceHubList:
      return data.resourceHubList.title;
    case scrList:
      return data.scrList.title;
    case genericPage:
      return data.genericPages.edges.map((i: any) => (i.node.url === pathname ? i.node.title : ""));
    case newsPage:
      return data.newsPages.edges.map((i: any) => (i.node.url === pathname ? i.node.title : ""));
    case organizations:
      return data.organizations.edges.map((i: any) => {
        const organizationsPath = `${data.acpList.url}/`;
        return organizationsPath + i.node.url === organizationsPath + pathname ? i.node.name : "";
      });
    case resourceHub:
      return data.resourceHubPages.edges.map((i: any) => {
        const resourceHubPath = `/resource-hub/${i.node.categorisation.content_type[0]?.slug}/`;
        return resourceHubPath + i.node.slug === resourceHubPath + pathname ? i.node.title : "";
      });
    default:
      return pathname
        .replace(/\/resource-hub\//, "") // "/resource-hub/" => ""
        .replace(/\-/g, " ") // "-" => " "
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter: any) => letter.toUpperCase());
  }
};

export const useBreadcrumbs = (location: any) => {
  const paths = location.pathname.replace(/\/$/, "").split("/");
  const crumbs: useBreadcrumbsProps = paths.map((i: any, index: any) => {
    return {
      pathname: i === "" ? "/" : `${paths.slice(0, index + 1).join("/")}`,
      crumbLabel: i,
    };
  });

  const label = Object.assign(crumbs.map((i: any) => getAllPageTitles(i.pathname)));
  const path = crumbs.map((i: any) => i.pathname);
  const breadcrumbs = path.map((x, i) => {
    const crumbLabel = typeof label[i] === "string" ? label[i] : label[i].filter((l: string) => l !== "")[0];
    return { pathname: x, crumbLabel: crumbLabel };
  });

  return { breadcrumbs };
};
