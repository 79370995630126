import React from "react";
import { htmlSafe, hasStartAndEndTag, wrapTableTags } from "modules/utils";
import { RichTextProps } from ".";

const RichTextSkeleton: React.FC<RichTextProps> = ({ content }) => {
  const tableSupport = wrapTableTags(content);
  const renderContent = htmlSafe(tableSupport);
  const safeContent = hasStartAndEndTag(content) ? renderContent : <p>{renderContent}</p>;
  return <div style={{ visibility: "hidden" }}>{safeContent}</div>;
};

export default RichTextSkeleton;
