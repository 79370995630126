import React from "react";
import { DocDownloadProps } from "..";

const DocDownloadSkeleton = ({ file, title }: DocDownloadProps) => {
  return (
    <div style={{ visibility: "hidden" }}>
      <div>
        <header>
          <h2>{title}</h2>
        </header>
        {file && file.length > 0 && (
          <ul>
            {file.map((i, key: any) => {
              return (
                <li key={key}>
                  <button aria-label={`Download ${i.title}`}>
                    <span>{i?.title}</span>
                  </button>
                </li>
              );
            })}
          </ul>
        )}
        {file && file.length > 0 === false && (
          <ul>
            <li>
              <button aria-label={`Download ${(file as any)?.title}`}>
                <span>{(file as any)?.title}</span>
              </button>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default DocDownloadSkeleton;
