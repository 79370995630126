import React from "react";
import { htmlSafe, hasStartAndEndTag, wrapTableTags } from "modules/utils";
import clsx from "clsx";

import * as styles from "./styles.module.scss";

export type RichTextProps = {
  content: string;
  largeText?: boolean;
};

const RichText: React.FC<RichTextProps> = ({ content, largeText }) => {
  const className = clsx(styles.richText, largeText && styles.largeText);
  const tableSupport = wrapTableTags(content);
  const renderContent = htmlSafe(tableSupport);
  const safeContent = hasStartAndEndTag(content) ? renderContent : <p>{renderContent}</p>;
  return <div className={className}>{safeContent}</div>;
};

export default RichText;
