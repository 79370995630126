import React from "react";
import { usePageReference } from "modules/unions/usePageReference";
import { TwoColumnImageProps } from "./types";

const TwoColumnImageSkeleton: React.FunctionComponent<TwoColumnImageProps> = ({
  columnOne,
  columnTwo,
  description,
  title,
}) => {
  let href1 = columnOne.cta?.page_link ? usePageReference(columnOne.cta?.page_link[0]) : columnOne.cta?.custom_url;
  let href2 = columnTwo.cta?.page_link ? usePageReference(columnTwo.cta?.page_link[0]) : columnTwo.cta?.custom_url;
  return (
    <div style={{ visibility: "hidden" }}>
      <div>
        {title && <h2>{title}</h2>}
        {description && <p>{description}</p>}
        <div>
          <div>
            <p>{columnOne.description}</p>
            {href1 && <a href={href1}>{columnOne.cta.label}</a>}
          </div>
        </div>
        <div>
          <div>
            <p>{columnTwo.description}</p>
            {href2 && <a href={href1}>{columnTwo.cta.label}</a>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoColumnImageSkeleton;
