import RichTextSkeleton from "components/RichText/Skeleton";
import React from "react";
import { AccordionProps } from ".";

const AccordionSkeleton: React.FC<AccordionProps> = ({ accordion_list, noContainer }) => {
  return noContainer ? (
    <ul style={{ visibility: "hidden" }}>
      {accordion_list?.map((i: any, key: any) => (
        <li key={key}>
          <div>
            <div>
              <h3>{i.title}</h3>
            </div>
          </div>
          <div>
            <div>
              {i.content?.map((i: any, key: any) => {
                return i.copy ? <RichTextSkeleton content={i.copy.copy} key={key} /> : null;
              })}
            </div>
          </div>
        </li>
      ))}
    </ul>
  ) : (
    <div style={{ visibility: "hidden" }}>
      <div>
        <ul>
          {accordion_list?.map((i: any, key: any) => (
            <li key={key}>
              <div>
                <div>
                  <h3>{i.title}</h3>
                </div>
              </div>
              <div>
                <div>
                  {i.content?.map((i: any, key: any) => {
                    return i.copy ? <RichTextSkeleton content={i.copy.copy} key={key} /> : null;
                  })}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AccordionSkeleton;
