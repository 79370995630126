import React from "react";
import { htmlSafe } from "modules/utils";
import { TestimonialsProps } from ".";

const TestimonialsSkeleton: React.FC<TestimonialsProps> = ({ testimonials }) => {
  return (
    <div style={{ visibility: "hidden" }}>
      <div className="container small">
        {testimonials?.length === 1 ? (
          testimonials?.map((item: any, key: any) => (
            <div key={key}>
              <div>
                <div>
                  <blockquote>{htmlSafe(item.testimonial?.reference[0]?.quote)}</blockquote>
                  <span>
                    {item.testimonial?.reference[0]?.title}
                    {item.testimonial?.reference[0]?.info ? `, ${item.testimonial?.reference[0]?.info}` : null}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>
            {testimonials?.map((item: any, key: any) => (
              <div>
                <div>
                  <div>
                    <blockquote>{htmlSafe(item.testimonial?.reference[0]?.quote)}</blockquote>
                    <span>
                      {item.testimonial?.reference[0]?.title}
                      {item.testimonial?.reference[0]?.info ? `, ${item.testimonial?.reference[0]?.info}` : null}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
};

export default TestimonialsSkeleton;
