import React from "react";
import { StatsProps } from ".";

const StatsSkeleton: React.FC<StatsProps> = ({ stat_1, stat_2, stat_3 }) => {
  const firstStat = stat_1.title !== "" && stat_1.number !== "",
    secondStat = stat_2?.title !== "" && stat_2?.number !== "",
    thirdStat = stat_3?.title !== "" && stat_3?.number !== "";

  return (
    <div style={{ visibility: "hidden" }}>
      <div>
        {firstStat && (
          <div>
            <div>
              <span>{stat_1?.number}</span>
            </div>
            <div>
              <p>{stat_1?.title}</p>
              {stat_1?.description && <p>{stat_1?.description}</p>}
            </div>
          </div>
        )}
        {secondStat && (
          <div>
            <div>
              <span>{stat_2?.number}</span>
            </div>
            <div>
              <p>{stat_2?.title}</p>
              {stat_2?.description && <p>{stat_2?.description}</p>}
            </div>
          </div>
        )}
        {thirdStat && (
          <div>
            <div>
              <span>{stat_3?.number}</span>
            </div>
            <div>
              <p>{stat_3?.title}</p>
              {stat_3?.description && <p>{stat_3?.description}</p>}
            </div>
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
};

export default StatsSkeleton;
